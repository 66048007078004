<template>
  <div>
    <!--begin::DocumentVQA-->
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <p>
          Question Answering and Natural Language Inference over Tables are
          pretty similar, though in the case of NLI, there is a statement to
          verify instead of a question to answer. We consider these tasks
          reformulated as Document Understanding by simply rendering the table
          and treating it as an actual document. The resulting scenario is
          similar to QA and MRC over documents, with the difference that a
          <em>document</em> now consists of a single born-digital table.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-xxl-8">
        <TableQA :list="list"></TableQA>
      </div>

      <div class="col-xxl-4">
        <StatWidget7
          category="Table QA and NLI"
          :progress="list.map(a => a.table).reverse()"
          color="dark"
        ></StatWidget7>
        <Paper></Paper>
      </div>
    </div>
    <!--end::DocumentVQA-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableQA from "@/view/content/widgets/list/TableQA.vue";
import StatWidget7 from "@/view/content/widgets/stats/Widget12.vue";
import Paper from "@/view/content/widgets/due/Paper.vue";
import { mapState } from "vuex";

export default {
  name: "table-qa",
  components: {
    TableQA,
    StatWidget7,
    Paper
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leaderboard" },
      { title: "Table QA/NLI" }
    ]);
  },
  computed: {
    ...mapState({
      list: state =>
        state.auth.leaderboard
          .filter(item => item.table > 0)
          .sort((a, b) => {
            return a.table - b.table;
          })
          .reverse()
    })
  }
};
</script>
